import React, { useState, useEffect } from "react";

import Layout from "../layout";
import Seo from "../seo";
import Blogs from "../blogs";
import * as Utils from "../../utils/utils";
import Subscribe from "../forms/subscribe";
import GTAButton from "../ui/gta-button";
import { BLOGS_PER_PAGE, BLOG_INCREMENT } from "../blogs";

const CategoryTemplate = ({
  posts,
  rootPath,
  activeCategory,
  blogPostsCategories,
  categoriesSummary,
}) => {
  // place featured on top of post
  posts.sort(function (x, y) {
    return x.featured === true ? -1 : y.featured === true ? 1 : 0;
  });

  // state for the list
  const [blogList, setBlogList] = useState([...posts.slice(0, BLOGS_PER_PAGE)]);

  // state for trigger loadMore button
  const [loadMore, setLoadMore] = useState(false);

  // state of whether there is no more to load
  const [hasMore, setHasMore] = useState(posts.length > BLOGS_PER_PAGE);

  // load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = blogList.length;
      const hasMoreToLoad = currentLength < posts.length;
      const nextResult = hasMoreToLoad
        ? posts.slice(currentLength, currentLength + BLOG_INCREMENT)
        : [];
      setBlogList([...blogList, ...nextResult]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, blogList, posts]);

  // check if there is more
  useEffect(() => {
    setHasMore(blogList.length < posts.length);
  }, [blogList, posts.length]);

  return (
    <Layout>
      <Seo
        title={Utils.firstCharUpperCase(posts?.[0]?.categories[0])}
        description={`Liste des blogs qui parlent de ${posts?.[0].categories[0]}`}
      />
      <Blogs
        blogPosts={blogList}
        categories={blogPostsCategories}
        title={activeCategory}
        fit
        rootPath={rootPath}
        categoriesSummary={categoriesSummary}
      />
      {hasMore && (
        <div style={{ textAlign: "center", marginBottom: "3rem" }}>
          <span aria-hidden="true" onClick={handleLoadMore}>
            <GTAButton>Voir plus</GTAButton>
          </span>
        </div>
      )}
      <Subscribe />
    </Layout>
  );
};

export default CategoryTemplate;
