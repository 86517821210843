import React from "react";
import { graphql } from "gatsby";

import CategoryTemplate from "../components/templates/category.template";
import { ICategorySummary } from "../components/blogs";

const ComponentName = ({ data, pageContext }) => {
  const {
    posts: { nodes: blogPosts },
  } = data;

  return (
    <CategoryTemplate
      posts={blogPosts}
      rootPath={pageContext.rootPath}
      activeCategory={pageContext.category}
      blogPostsCategories={pageContext.blogPostsCategories}
      categoriesSummary={pageContext.categoriesSummary}
    />
  );
};

export const query = graphql`
  query ($category: String) {
    posts: allContentfulBlogPost(
      filter: { categories: { eq: $category } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        description {
          description
        }
        body {
          body
        }
        publishDate(formatString: "DD.MM.YYYY", locale: "fr")
        categories
        readTime
        heroImage {
          file {
            fileName
          }
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 100
            height: 50
            resizingBehavior: FILL
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default ComponentName;
